import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './translates/en.json'
import ko from './translates/ko.json'
const resources = {
  en: {
    translation: en
  },
  ko: {
    translation: ko
  }

}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: "en", // set fallback language to english
    supportedLngs: ['en', 'ko'],
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    resources: resources
  });

const browserLanguage = navigator.language || navigator.userLanguage; // Get the language setting of the browser

if (browserLanguage === 'en' || browserLanguage === 'en-US') {
  i18n.changeLanguage('en'); // Set the language to English if the browser language is English
} else if (browserLanguage === 'ko') {
  i18n.changeLanguage('ko'); // Set the language to Korean if the browser language is Korean
}