import { Link, Route, Routes, createBrowserRouter } from "react-router-dom";
// import EntryScreen from "../../feature/EntryScreen";
import FinalScreen from "../../feature/FinalScreen";
import ErrorScreen from "../../feature/ErrorScreen";
import CheckInScreen from "../../feature/CheckInScreen";
import DeniedScreen from "../../feature/DeniedScreen";

function Root() {
  return (
    <Routes>
      <Route path="/check-in" element={<CheckInScreen />} />
      <Route path="/check-in-failed" element={<ErrorScreen />} />
      <Route path="/check-in-success" element={<FinalScreen />} />
      <Route path="/check-in-denied" element={<DeniedScreen />} />
    </Routes>
  );
}

export const router = createBrowserRouter([{ path: "*", Component: Root }]);
