import { Box, Button, Container, styled, Typography } from "@mui/material";
import theme from "../../utils/theme";
import { useTranslation } from "react-i18next";
import "./index.css";

const DeniedScreen = () => {
  const { t } = useTranslation();
  const handleSubmit = () => {
    window.open(window.location.href, "_self");
    setTimeout(() => {
      window.close();
    }, 1000);
  };
  const err = localStorage.getItem("errorKey");

  return (
    <>
      <Container
        className="error-screen"
        maxWidth="lg"
        sx={{
          background: theme.font_color.primary,
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            height: "100vh",
            "@media screen and (min-width: 768px)": {
              fontSize: "30px",
              minWidth: "700px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              marginBottom: "50px",
              marginTop: "70px",
            }}
          >
            <Typography
              sx={{
                color: "#526F97",
                "@media screen and (min-width: 768px)": {
                  fontSize: "20px",
                },
              }}
            >
              {t(err)}
            </Typography>
            <Typography
              sx={{
                color: "#526F97",
                "@media screen and (min-width: 768px)": {
                  fontSize: "20px",
                },
              }}
            ></Typography>
          </Box>
          <Button
            variant="contained"
            href="about:blank"
            sx={{
              width: "100%",
              backgroundColor: "#33b9c7 !important",
              position: "fixed",
              bottom: "0",
              left: "0",
              borderRadius: "0",
              padding: "20px",
              fontSize: "20px",
              fontWeight: "bold",
              color: "#ffffff",
              hover: {
                backgroundColor: "#33b9c7 !important",
              },
              // active
              active: {
                backgroundColor: "#33b9c7 !important",
              },
            }}
            onClick={handleSubmit}
          >
            {t("OK")}
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default DeniedScreen;
