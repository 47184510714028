// axios.defaults.baseURL = "http://14.225.255.245:9800/api/check-in-room"
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
};


export const checkInRoom = async (roomName, latitude, longitude, contractCode) => {
    try {
        const response = await axios.post("/access/check-in-room",
            {
                roomName: roomName,
                latitude: latitude,
                longitude: longitude,
                contractCode: contractCode
            }
        );

        localStorage.setItem("checkInTime", response?.data?.content?.date);
        localStorage.setItem("companyName", response?.data?.content?.companyName);
        localStorage.setItem("representativeName", response?.data?.content?.representativeName);
        return response?.data?.content;
    } catch (error) {
        throw error
    }
};

export default {
    checkInRoom,
};
