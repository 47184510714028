import { Box, Container, styled } from "@mui/material";
import { TextField, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NumPad from "../../component/Numpad";
import { useNavigate, useLocation } from "react-router-dom";
import { useRef } from "react";
import { checkInRoom } from "../../API/userAPI";
import theme from "../../utils/theme";
import PhoneInput from "../../component/PhoneInput";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import "./index.css";
import { ca } from "date-fns/locale";

export default function CheckInScreen() {
  const { t } = useTranslation();
  const [hideNumpad, setHideNumpad] = useState(false);
  const [isUsernameFocused, setIsUsernameFocused] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const roomName = searchParams.get("roomName");
  const contractCode = searchParams.get("contractCode");
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.log(error);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              //user denied
              localStorage.setItem("errorKey", "PERMISSION_DENIED");
              break;
            case error.POSITION_UNAVAILABLE:
              //device turn off
              localStorage.setItem("errorKey", "POSITION_UNAVAILABLE");
              break;
            case error.TIMEOUT:
              //timeout
              localStorage.setItem("errorKey", "TIMEOUT");
              break;
          }
          navigate("/check-in-denied");
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 10000,
        }
      );
    } else {
      console.log("Geolocation is not supported.");
    }

    (function checkin() {
      try {
        if (latitude && longitude) {
          checkInRoom(roomName, latitude, longitude, contractCode)
            .then((res) => {
              localStorage.setItem("checkInTime", res?.date);
              localStorage.setItem("companyName", res?.companyName);
              localStorage.setItem(
                "representativeName",
                res?.representativeName
              );
              navigate("/check-in-success");
            })
            .catch((error) => {
              console.log("checkin", error);
              localStorage.setItem("roomName", roomName);
              localStorage.setItem("errorKey", error.response.data.type)
              navigate("/check-in-failed");
            });
        }
      } catch (error) {}
    })();
  }, [longitude, latitude]);

  return (
    <>
      <Box
        className="check-in-screen"
        sx={{
          display: "flex",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            width: "100vw",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: theme.font_color.ternary,
          }}
        >
          <h2>{t("CHECK_LOCATION")}!</h2>
          <CircularProgress color="inherit" />
        </Box>
      </Box>
    </>
  );
}
