import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
/**
 * design background
 * #373737
 * #505361
 * #84899C
 * #F2F2F2
 * 
 * design button bg
 * #84899C  color FFFFFF
 * #33b9c7  color FFFFFF
 * #FFFFFF  color 33B9C7
 * #F7F7F7  color 000000
 * #F4F4F4  color 000000
 * 
 * design border
 * #BEBEBE
 * #F2F2F2
 * 
 * design font color
 * #FFFFFF
 * #000000
 * #33B9C7
 * #84899C
 * #FF1EAD
 * 
 * font
 * S-Core Dream 4
 * S-Core Dream 3 for button
 * 
*/
const theme = createTheme({
  color: {
    primary: '#373737',
    secondary: '#505361',
    ternary: '#84899C',
    error: red.A400,
    background: '#F2F2F2',
    divider: '#BEBEBE',
    white: '#FFFFFF',
    black: '#000000',
  },
  border: {
    primary: '1px solid #BEBEBE',
    secondary: '#F2F2F2',
  },
  background_color: {
    primary: '#373737',
    secondary: '#505361',
    ternary: '#84899c',
    quaternary: '#f2f2f2',
    quinary: '#f7f7f7',
    active: '#33b9c7',
  },
  font_color: {
    primary: '#FFFFFF',
    secondary: '#000000',
    ternary: '#33B9C7',
    quaternary: '#84899C',
    quinary: '#FF1EAD',
  },
  text: {
    fontFamily: [
      'S-Core Dream 4',
      'sans-serif',
    ].join(','),
    fontSize: {
      xsm: '12px',
      sm: '14px',
      md: '16px',
      lg: '18px',
      xl: '20px',
      xxl: '24px',
      xxxl: '28px',
    },
  },



  button: {
    primary: {
      borderRadius: 0,
      textTransform: 'Uppercase',
      fontSize: 16,
      padding: '6px 12px',
      border: 'none',
      lineHeight: 1.5,
      backgroundColor: '#84899C',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#33b9c7',
        color: '#FFFFFF',
        boxShadow: 'none'
      },
      '&:active': {
        background: '#33b9c7',
        color: '#FFFFFF',
        border: 'none',

      },
      '&:focus': {
      },
    },
    secondary: {
      borderRadius: 0,
      textTransform: 'Capitalize',
      boxShadow: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: 'none',
      lineHeight: 1.5,
      backgroundColor: '#33b9c7',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#33b9c7',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
      },
      '&:active': {
        background: '#84899C',
        color: '#FFFFFF',
        border: 'none',
      }
    }
    , ternary: {
      borderRadius: 0,
      textTransform: 'Capitalize',
      boxShadow: 'none',
      fontSize: 16,
      padding: '6px 38px',
      border: '1px solid #33b9c7',
      lineHeight: 1.5,
      backgroundColor: 'transparent',
      color: '#33b9c7',
      '&:hover': {
        // backgroundColor: '#33b9c7',
        // color: '#FFFFFF',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
      },
      '&:active': {
        // background: '#33b9c7',
        // color: '#FFFFFF',
        // border: 'none',
      }
    }
  },
  palette: {
    primary: {
      main: '#373737',
    },
    secondary: {
      main: '#505361',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#F2F2F2',
    },
    divider: '#373737',

  },

  typography: {
    fontFamily: [
      'S-Core Dream 4',
      'sans-serif',
    ].join(','),
    button: {
      fontFamily: 'S-Core Dream 3',
      textTransform: 'none',
    },
    title: {
      fontFamily: 'S-Core Dream 4',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#000000',
    },
    label: {
      fontFamily: 'S-Core Dream 4',
      fontSize: '14px',
      fontWeight: 'normal',
      color: '#000000',
    },
    h1: {
      fontFamily: 'S-Core Dream 4',
      fontSize: '2.5rem',
      fontWeight: 'bold',
      color: '#000000',
    },
    h2: {
      fontFamily: 'S-Core Dream 4',
      fontSize: '2rem',
      fontWeight: 'bold',
      color: '#000000',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h1',
          h3: 'h1',
          h4: 'h1',
          h5: 'h1',
          h6: 'h1',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },

      },

    },
  },



});

export default theme;