import { Box, Button, Container, styled, Typography } from "@mui/material";
import theme from "../../utils/theme";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./index.css";
// import { checkInRoom } from "../../API/userAPI";
// import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const FinalScreen = () => {
  const { t } = useTranslation();
  // const browserLanguage = navigator.language || navigator.userLanguage;
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedDay, setFormattedDay] = useState("");
  const [formattedTime, setFormattedTime] = useState("");
  const userName = localStorage.getItem("representativeName");
  const checkInTime = localStorage.getItem("checkInTime");
  const companyName = localStorage.getItem("companyName");

  // const date = new Date(parseInt(checkInTime));
  // const formattedYear = format(date, "yyyy");
  // const formattedMonth = format(date, "MMMM");
  // const formattedDay1 = format(date, "d");
  // const formattedDate = `${formattedYear}${t(" ")}${t(formattedMonth)}${t(
  //   " "
  // )}${t(formattedDay1)}`;
  // const formattedTime = date.toLocaleTimeString([], {
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   hour12: false,
  // });
  // const formattedDay = date.toLocaleDateString("en-US", { weekday: "long" });

  useEffect(() => {
    if (checkInTime) {
      const date = new Date(parseInt(checkInTime));
      const formattedYear = format(date, "yyyy");
      const formattedMonth = format(date, "MMMM");
      const formattedDay1 = format(date, "d");
      const formattedTime = date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const formattedDay = date.toLocaleDateString("en-US", {
        weekday: "long",
      });
      setFormattedDay(formattedDay);
      setFormattedTime(formattedTime);

      const formattedDate = `${formattedYear}${t(" ")}${t(formattedMonth)}${t(
        " "
      )}${t(formattedDay1)}`;
      setFormattedDate(formattedDate);
    }
  }, []);

  const handleSubmit = () => {
    window.open(window.location.href, "_self");
    setTimeout(() => {
      window.close();
    }, 1000);
  };

  return (
    <>
      <Container
        className="final-screen"
        maxWidth="lg"
        sx={{
          background: theme.font_color.primary,
          overflow: "hidden",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            height: "100vh",
            "@media screen and (min-width: 768px)": {
              fontSize: "30px",
              minWidth: "700px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              marginBottom: "50px",
              marginTop: "50px",
              "@media screen and (max-width: 280px)": {
                margin: "10px",
              },
              "@media screen and (max-height: 568px)": {
                marginTop: "10px",
              },
              "@media screen and (max-height: 400px)": {
                marginBottom: 0,
                fontSize: "14px",
              },
            }}
          >
            <h3>
              {userName} {t("Sir/Ms,")}
            </h3>
            <Typography
              sx={{
                color: "#526F97",
                "@media screen and (min-width: 768px)": {
                  fontSize: "20px",
                },
                "@media screen and (max-height: 400px)": {
                  fontSize: "12px",
                },
              }}
            >
              {t("The check-in process is complete")}.
            </Typography>
          </Box>

          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "#f8f8f8",
              boxShadow: "none",
            }}
          >
            <Table
              sx={{
                border: "none",
                boxShadow: "none",
                "@media screen and (max-height: 400px)": {
                  fontSize: "10px",
                },
              }}
            >
              <TableBody sx={{ boxShadow: "none" }}>
                <TableRow>
                  <StyledTableCell
                    sx={{
                      whiteSpace: "nowrap",
                      verticalAlign: "top",
                      fontWeight: "bold",
                    }}
                  >
                    <FiberManualRecordIcon
                      sx={{
                        fontSize: "6px",

                        color: "red",
                        display: "inline-block",
                        marginRight: "15px",
                        marginBottom: "2px",
                        verticalAlign: "center",
                      }}
                    />
                    {t("Check-in time")}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      pl: "5px",
                    }}
                  >
                    {formattedDate || " "}
                    {" ("}
                    {t(formattedDay) || "day"}
                    {") "}
                    {formattedTime || " "}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow>
                  <StyledTableCell
                    sx={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      verticalAlign: "top",
                    }}
                  >
                    <FiberManualRecordIcon
                      sx={{
                        fontSize: "6px",
                        color: "red",
                        display: "inline-block",
                        marginRight: "15px",
                        marginBottom: "2px",
                        verticalAlign: "center",
                      }}
                    />
                    {t("Company name")}
                  </StyledTableCell>

                  <StyledTableCell
                    sx={{
                      wordBreak: "break-word",

                      pl: "5px",
                    }}
                  >
                    {companyName || " "}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            href="about:blank"
            sx={{
              width: "100%",
              backgroundColor: "#33b9c7 !important",
              position: "fixed",
              bottom: "0",
              left: "0",
              borderRadius: "0",
              padding: "20px",
              fontSize: "20px",
              fontWeight: "bold",
              color: "#ffffff",
              hover: {
                backgroundColor: "#33b9c7 !important",
              },
              // active
              active: {
                backgroundColor: "#33b9c7 !important",
              },
            }}
            onClick={handleSubmit}
          >
            {t("OK")}
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default FinalScreen;
const StyledTableCell = styled(TableCell)(() => ({
  borderBottom: "none",
  fontSize: "12px",
  "@media screen and (min-width: 320px)": {
    fontSize: "12px",
  },
  "@media screen and (min-width: 375px)": {
    fontSize: "14px",
  },

  "@media screen and (min-width: 768px)": {
    fontSize: "20px",
  },
  "@media screen and (max-height: 400px)": {
    fontSize: "10px",
  },
}));
